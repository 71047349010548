import ApiService from '@/util/api.service';

const generateCameraForm = (value, form, values) => {
  ApiService({
    url: `/v2/video-servers/${value}`,
    method: 'get',
  })
    .then((item) => {
      const newSchema = {
        server: {
          label: 'Servidor',
          type: String,
          model: 'v2/video-servers',
          autocomplete: false,
          //  eslint-disable-next-line object-shorthand
          onChange: function (val, fields, vals) {
            if (val) {
              generateCameraForm(val, form, vals);
            }
          },
        },
      };
      item.metadata.forEach((meta) => {
        newSchema[meta.name] = {
          label: meta.label,
          type: window[meta.type] || String,
          defaultValue: values?.[meta.name],
          optional: meta.optional
        };
      });
      form.initSchema(newSchema);
    })
    .catch((err) => {
      console.log(err);
      alert('ERROR');
    });
};

export default {
  id: {
    type: Number,
    visibility: false,
  },
  name: {
    label: 'Nombre',
    type: String,
  },
  uniqueId: {
    label: 'Identificador',
    type: String,
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    module: 'devices',
    autocomplete: false,
  },
  phone: {
    label: 'Teléfono',
    type: String,
    optional: true,
  },
  model: {
    label: 'Modelo',
    type: String,
    optional: true,
  },
  contact: {
    label: 'Contacto',
    type: String,
    optional: true,
  },
  category: {
    label: 'Categoría',
    type: String,
    model: 'v2/device-categories',
    autocomplete: false,
  },
  fuelType: {
    label: 'Tipo de combustible',
    type: String,
    options: [
      {
        id: 'magna',
        name: 'Magna',
      },
      {
        id: 'premium',
        name: 'Premium',
      },
      {
        id: 'diesel',
        name: 'Diesel',
      },
      {
        id: 'gas',
        name: 'Gas',
      },
    ],
    optional: true,
  },
  kmPerLiter: {
    label: 'Kilometros por litro',
    type: Number,
    optional: true,
  },
  conductor: {
    label: 'Conductor',
    type: String,
    model: 'v2/drivers',
    autocomplete: false,
    optional: true,
  },
  disabled: {
    label: 'Deshabilitado',
    type: Boolean,
    optional: true,
  },
  instantMessenger: {
    label: 'Mensajería instantanea',
    type: String,
    optional: true,
    options: [
      {
        id: 'None',
        name: 'Ninguna',
      },
      {
        id: 'Whatsapp',
        name: 'Whatsapp',
      },
      {
        id: 'Telegram',
        name: 'Telegram',
      },
    ],
  },
  phoneList: {
    label: 'Listado de teléfonos',
    type: Array,
    optional: true,
  },
  'phoneList.$': {
    type: String,
    options: true,
  },
  isDvr: {
    label: 'Es DVR?',
    type: Boolean,
    onChange: (val, fields) => {
      fields.dvrCode.setVisibility(val);
      // fields.attributes.setVisibility(val);
    },
  },
  dvrCode: {
    label: 'Codigo DVR',
    type: String,
  },
  cameras: {
    label: 'Camaras',
    type: Array,
    title: (item) => {
      return item.name;
    },
    optional: true,
    onChange: (val, field) => {
      // field.numberOfCameras.value = val ? val.length : 0
      field.cameras.value.forEach((attr, i) => {
        attr.name = 'dev' + i;
      });
    },
  },
  'cameras.$': {
    type: {
      server: {
        label: 'Servidor',
        type: String,
        model: 'v2/video-servers',
        autocomplete: false,
        //  eslint-disable-next-line object-shorthand
        onChange: function (val, fields, values) {
          if (val) {
            generateCameraForm(val, this, values);
          }
        },
      },
    },
  },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true,
    visibility: false,
  },
};
